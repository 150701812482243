import React from "react"
import style from "./filter.mod.scss"
import { FaRedo } from "@react-icons/all-files/fa/FaRedo"
import { Button, Select } from "@components"

const Filter = ({ onClick, onChange, filters = [], displayText }) => {
  if (filters.length > 3) {
    filters = filters.splice(0, 3)
  }
  let classes = [style.filter]
  return (
    <div className={classes.join(" ")}>
      {filters.length > 0 && (
        <div className={style.filter__selects}>
          {filters.map((filter, i) => {
            return (
              <Select
                {...filter}
                value={filter.value} // Pass the value prop
                row={filters.length > 1}
                fullWidth={filters.length <= 1}
                strongBorder
                key={`select--${filter.name}-i`}
                onChange={onChange}
              />
            )
          })}
        </div>
      )}
      <div className={style.filter__controls}>
        {/* <Button type="submit" children={`Refine ${displayText}`} /> */}
        <Button
          type="reset"
          onClick={e => onClick(e)}
          children={<FaRedo />}
          secondary
          white
          circular
        />
      </div>
    </div>
  )
}

export default Filter
